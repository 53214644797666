import advertiserReportsAPI from '~/api/advertiser-reports';
import { buildQueryString } from '~/helpers/global/url-helpers';

const methods = {
  async fetchEvents(cachedEventOptions = null) {
    try {
      if (cachedEventOptions === null) {
        const data = await advertiserReportsAPI.events(
          this.advertiser ? this.advertiser.id : 0,
          buildQueryString({
            advertiser: this.advertiser ? this.advertiser.name : '',
            client: this.account ? this.account.name : '',
            xandrId: this.advertiser ? this.advertiser.xandrAdvertiserId : '',
            pixelId: this.universalPixelId ? this.universalPixelId : '',
          })
        );

        data.forEach((element) => {
          element.open = element.selected;
        });
        this.$store.set('dashboard/filters@eventOptions', data);
        return;
      }
      this.$store.set('dashboard/filters@eventOptions', cachedEventOptions);
    } catch (err) {
      console.error('error fetching events ->', err);
      if (window.$sentry) {
        if (err._reported !== true) {
          window.$sentry.captureException(err);
          err._reported = true;
        }
      }
      throw err;
    }
  },
};

export default { methods };
