var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isMniAccount && _vm.eventLoadingForMni)?_c('div',{staticClass:"txt-center event-spinner-border",style:(`${_vm.isMniAccount ? 'margin-right: 10px' : ''}`)},[_c('b-loading-spinner2',{staticClass:"event-spinner",attrs:{"width":20}})],1):_c('div',{ref:"b-select-checkbox",staticClass:"dropdown noselect",class:{ disabled: _vm.disabled },style:(`${_vm.isMniAccount ? 'margin-right: 10px' : 'margin-right: 20px'}`),attrs:{"tabindex":"1"}},[_c('div',{staticClass:"select",class:[_vm.showDropdown ? 'open' : ''],on:{"click":_vm.toggleDropdown}},[_c('div',{staticClass:"selected-opt-wrapper"},[_c('span',{staticClass:"selected-opt-header"},[_vm._v(_vm._s(_vm.header ? `${_vm.header}: ` : ''))]),_c('span',{staticClass:"selected-opt"},[_vm._v(_vm._s(_vm.isMniAccount && _vm.selected === 'Checkouts' ? 'Conversions' : _vm.selected === 'Video Completions' ? 'Completions' : _vm.selected))])]),_c('font-awesome-icon',{staticClass:"droparw",attrs:{"icon":['far', _vm.showDropdown ? 'chevron-up' : 'chevron-down']}})],1),(_vm.showDropdown)?_c('ul',{staticClass:"dropdown-menu",attrs:{"id":_vm.dropdownId}},_vm._l((_vm.optionsCopy),function(option,i){return _c('li',{key:i,class:[
        option.showStar ? 'rollup-option' : 'norm-option',
        option.disabled ? 'disabled' : '',
        option.selected ? 'active' : '',
        option.children && option.children.length && option.open ? 'has-children' : '',
      ],on:{"click":function($event){return _vm.selectOption(i)}}},[_c('div',{staticClass:"main",class:{
          'child-open':
            option.children && option.children.length && option.selected && option.open,
        },on:{"mouseover":(el) => _vm.handleMouseOver(el, i + 1, option.event),"mouseleave":_vm.handleMouseLeave}},[(option.showStar)?_c('div',{staticClass:"roll-preset"},[_c('font-awesome-icon',{staticClass:"check-on checkbox-active",attrs:{"icon":['fas', 'star']}})],1):_vm._e(),_c('font-awesome-icon',{staticClass:"check",class:{ 'check-off': !option.selected, wl: _vm.applyWhiteLabeling },attrs:{"icon":['fa', 'check'],"size":"6x"},on:{"click":function($event){$event.stopPropagation();return _vm.handleCheckBtnClick(option, i)}}}),_c('span',{staticClass:"norm-option-item"},[_vm._v(_vm._s(_vm.isMniAccount && option.event === 'Checkouts' ? 'Conversions' : option.event === 'Video Completions' ? 'Completions' : option.event)+" ")]),(option.children && option.children.length)?_c('font-awesome-icon',{staticClass:"droparw",attrs:{"icon":['far', option.open ? 'chevron-up' : 'chevron-down']},on:{"click":function($event){$event.stopPropagation();return _vm.onClickOption(option, i + 1)}}}):_vm._e()],1),(option.children && option.children.length && option.open)?_c('ul',{staticClass:"sub"},_vm._l((option.children),function(child,ci){return _c('li',{key:ci,on:{"click":function($event){$event.stopPropagation();child.selected = !child.selected;
            _vm.changeSelection(i);
            _vm.handleMouseLeave();
            option.open = _vm.disableDropDownSelectOption ? option.open : false;
            _vm.$emit('selected-options', _vm.optionsCopy, child);},"mouseover":(el) => _vm.handleMouseOver(el, i + 1, child.event, ci + 1),"mouseleave":_vm.handleMouseLeave}},[_c('font-awesome-icon',{staticClass:"check",class:{ 'check-off': !child.selected, wl: _vm.applyWhiteLabeling },attrs:{"icon":['fa', 'check'],"size":"6x"}}),_c('span',[_vm._v(_vm._s(child.event)+" ")])],1)}),0):_vm._e()])}),0):_vm._e(),_c('div',{staticClass:"tooltipText",style:(_vm.activeEl.style)},[_vm._v(" "+_vm._s(_vm.isMniAccount && _vm.activeEl.text === 'Checkouts' ? 'Conversions' : _vm.activeEl.text === 'Video Completions' ? 'Completions' : _vm.activeEl.text)+" ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }