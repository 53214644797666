import { render, staticRenderFns } from "./youtube-report.vue?vue&type=template&id=4849e5aa&scoped=true"
import script from "./youtube-report.vue?vue&type=script&lang=js"
export * from "./youtube-report.vue?vue&type=script&lang=js"
import style0 from "./youtube-report.vue?vue&type=style&index=0&id=4849e5aa&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4849e5aa",
  null
  
)

export default component.exports