var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app-board",class:_vm.getAppParentClassState()},[(!_vm.leftNavLoading)?_c('left-nav-section',{attrs:{"options":_vm.leftNavOptions,"is-mni-dashboard":_vm.isMniDashboard,"is-via-dashboard":_vm.isViaDashboard,"is-collapsed":_vm.isLeftNavCollapsed,"is-campaign-table-expanded":_vm.isCampaignTableExpanded,"default-selected-solution":_vm.getDefaultSolution},on:{"option-click":_vm.handleLeftNavClick,"on-left-nav-toggle":_vm.handleLeftNavToggle}}):_c('div',{staticClass:"dashboard-loading-spinner"},[_c('b-loading-spinner')],1),(_vm.noAdvertiserAssined)?_c('div',{staticClass:"no-advertiser-container"},[_vm._v(" Oops! It looks like no advertisers are assigned to your account. Please contact the administrator for assistance. ")]):_vm._e(),(_vm.isSpinnerVisible)?_c('div',{staticClass:"dashboard-loading-spinner"},[_c('b-loading-spinner')],1):[(_vm.selectedSolutionHandler !== 'EmbeddedDashboard')?_c('div',{class:[
        _vm.isCampaignTableExpanded ? 'shift-top' : '',
        _vm.isMniDashboard && _vm.isPixelPageVisible
          ? 'mni-body-content-container'
          : 'body-content-container',
      ]},[(_vm.showTopFilterSection)?_c('div',[(!_vm.leftNavLoading)?_c('top-filter-section',{class:_vm.disableFilterBar ? 'inactive-filter-bar' : '',attrs:{"hide-filter-bar":_vm.solutionLoading,"is-left-nav-collapsed":_vm.isLeftNavCollapsed,"title":_vm.selectedSectionTitle,"is-campaign-table-expanded":_vm.isCampaignTableExpanded,"active-solution-id":_vm.selectedSolution.id,"filters-to-hide":_vm.filtersToHide},on:{"show-pixel-page":function($event){_vm.isPixelPageVisible = $event}}}):_vm._e()],1):_vm._e(),(_vm.isPixelPageVisible && !_vm.solutionLoading)?_c('flip-pixel'):_c('div',{staticClass:"body-content",class:_vm.isCampaignTableExpanded ? 'fullmax' : ''},[(_vm.selectedSolutionHandler && !_vm.solutionLoading)?_c(_vm.selectedSolutionHandler,{tag:"component",attrs:{"active-solution-id":_vm.selectedSolution.id,"active-dashboard":_vm.selectedDashboard,"is-left-nav-collapsed":_vm.isLeftNavCollapsed,"is-campaign-table-expanded":_vm.isCampaignTableExpanded},on:{"on-expand-campaign-table":_vm.handleExpandCampaignTable}}):_vm._e()],1),(_vm.solutionLoading)?_c('div',{staticClass:"solution-loading-spinner"},[_c('b-loading-spinner')],1):_vm._e()],1):_c('div',[_c('div',{staticClass:"emb-wrap"},[_c('b-flip-header',{attrs:{"show-logo":_vm.account != null && !_vm.isEmbed,"show-title":_vm.isEmbed,"title":_vm.embedTitle,"show-top-navigation":_vm.showTopNavigation,"is-left-nav-collapsed":_vm.isLeftNavCollapsed}}),_c('top-filter-section',{attrs:{"hide-filter-bar":_vm.solutionLoading,"is-left-nav-collapsed":_vm.isLeftNavCollapsed,"title":_vm.selectedSectionTitle,"is-campaign-table-expanded":_vm.isCampaignTableExpanded,"active-solution-id":_vm.isMniDashboard ? 8 : _vm.selectedSolution.id,"filters-to-hide":_vm.filtersToHide,"is-mni-report-dashboard":_vm.isMniDashboard,"is-advance-filter-in-overview":true,"is-hidden":_vm.isMniDashboard ? false : true},on:{"show-pixel-page":function($event){_vm.isPixelPageVisible = $event}}})],1),(
          _vm.selectedMenuOption === _vm.LAYOUT_TYPES.DASHBOARD &&
            _vm.selectedDashboard.embedUrlType === 'flip'
        )?_c('DashboardFlip',{attrs:{"is-advance-filter-in-overview":true}}):_vm._e(),(
          _vm.selectedMenuOption === _vm.LAYOUT_TYPES.DASHBOARD &&
            _vm.selectedDashboard.embedUrlType === 'flip_report' &&
            !_vm.loading
        )?_c('FlipReportBuilder',{attrs:{"is-mni-dashboard":_vm.isMniDashboard,"is-advance-filter-in-overview":true}}):_vm._e(),(_vm.selectedMenuOption === _vm.LAYOUT_TYPES.ACCOUNT_SETUP && !_vm.loading)?_c('AccountSetupScreen'):_vm._e(),(_vm.selectedMenuOption === _vm.LAYOUT_TYPES.FLIPPIXEL && !_vm.loading)?_c('flip-pixel'):_vm._e(),(_vm.selectedMenuOption === _vm.LAYOUT_TYPES.ADMIN && !_vm.loading)?_c('Admin'):_vm._e()],1)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }